//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import CalendarMixin from "@/mixins/Calendar";
import AppSelect from "@/components/AppSelect.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import Calendar from "@/components/Calendar.vue";
import { dateRangeBoundaries, dateRangeFromDate, today } from "@/utils/dates";
import dayjs from "dayjs";
import LoadingSpinner from "@/components/Loading/Spinner";
import DeliveryCalculationTable from "@/components/Connections/DeliveryCalculationTable";
import MyShopsMixin from "@/mixins/MyShops";
import HasTokens from "@/components/HasTokens";
import Tooltip from "@/components/Tooltip";
const FORMAT = "YYYY-MM-DD";
export default {
  mixins: [CalendarMixin, MyShopsMixin],
  meta: {
    title: "Расчет поставки"
  },
  data() {
    const select_days_items = [{
      id: 0,
      title: "7 дней",
      value: 7
    }, {
      id: 1,
      title: "30 дней",
      value: 30
    }, {
      id: 2,
      title: "60 дней",
      value: 60
    }];
    const localStorageCalendar = JSON.parse(localStorage.getItem("calendar"));
    if (localStorageCalendar) {
      this.$store.dispatch("connections/updateCalendar", localStorageCalendar);
    }
    return {
      calendarInternal: null,
      calendarInternal2: "",
      calendar: this.$store.state.connections.calendar,
      select_days_items,
      selectedDays: select_days_items[1],
      select_cluster_items: [],
      selectedClusters: [],
      ratio: "1.00",
      days: "30",
      progress: 0,
      progress_prev: 0,
      progress_show: 0,
      loadingDeliveryCalc: false,
      showSettings: true,
      hash: "",
      refreshKey: 0,
      firstTime: true,
      calcError: ""
    };
  },
  methods: {
    async loadStores() {
      var _cluster;
      let cluster = null;

      //! &: 20204-06-27: oz: store -> cluster
      if (this.marketplace.title === "OZON") {
        cluster = await this.$store.dispatch("connections/getOzStores", {});
      }
      if (this.marketplace.title === "Wildberries") {
        cluster = await this.$store.dispatch("connections/getWbStores", {
          datasource: this.marketplace.id
        });
      }

      //let hasValue = false;
      //let index = null;

      this.select_cluster_items = [];
      (_cluster = cluster) === null || _cluster === void 0 || _cluster.data.forEach(item => {
        /*
        hasValue = false;
        this.select_cluster_items.forEach((el, i) => {
            if (el.title === item.group_name) {
                hasValue = true;
                index = i;
            }
        });
        if (!hasValue) {
            this.select_cluster_items.push({
                id: item.id + item.name,
                title: item.group_name,
                value: [item.id],
            });
        } else {
            this.select_cluster_items[index].value.push(item.id);
        }
        */
        //&:
        this.select_cluster_items.push({
          id: item.cluster_id,
          title: item.cluster_name
        });
      });
      this.selectedClusters = [];
    },
    async createRequest() {
      this.calcError = "";
      this.loadingDeliveryCalc = true;
      this.progress = 0;
      this.progress_prev = 0;
      this.progress_show = 0;
      //this.hash = Math.random().toString(16).substr(2, 19);
      this.hash = Math.random().toString(16).slice(2, 2 + 19);
      if (this.marketplace.title === "OZON") {
        await this.$store.dispatch("connections/createOzCalcDeliveryRequest", {
          hash: this.hash,
          parameters: this.reportSettings
        });
      }
      if (this.marketplace.title === "Wildberries") {
        await this.$store.dispatch("connections/createWbCalcDeliveryRequest", {
          hash: this.hash,
          parameters: this.reportSettings
        });
      }
      setInterval(() => {
        if (this.progress < 100) {
          this.loadProgress(this.hash);
        } else {
          this.loadingDeliveryCalc = false;
        }
      }, 5000);
    },
    async loadProgress(hash) {
      var _progress, _progress2;
      let progress = null;
      if (this.marketplace.title === "OZON") {
        progress = await this.$store.dispatch("connections/getOzCalcDeliveryProgress", {
          hash
        });
      }
      if (this.marketplace.title === "Wildberries") {
        progress = await this.$store.dispatch("connections/getWbCalcDeliveryProgress", {
          hash
        });
      }

      //console.log('398: progress=',progress)

      this.progress = Number((_progress = progress) === null || _progress === void 0 ? void 0 : _progress.data.progress);
      if (this.progress == 100) {
        //eof
      } else {
        let dec = 2;
        if (this.progress - this.progress_prev >= 1) {
          dec = 0;
        } else if (this.progress - this.progress_prev >= 0.1) {
          dec = 1;
        } else if (this.progress - this.progress_prev >= 0.01) {
          dec = 2;
        }
        this.progress_show = this.progress.toFixed(dec);
        this.progress_prev = this.progress;
      }

      //console.log('402: this.progress=',this.progress)

      this.calcError = (_progress2 = progress) === null || _progress2 === void 0 ? void 0 : _progress2.data.error;
      if (this.calcError != "") {
        this.loadingDeliveryCalc = false;
        this.progress = 100;
      }
    },
    toggleSettings() {
      this.showSettings = !this.showSettings;
    },
    loadCalendarBoundaries() {
      const date2 = new Date();
      date2.setDate(date2.getDate());
      this.calendarBoundaries = ["2022-03-26",
      // date2.toISOString().split('T')[0]
      ""];
    },
    loadCalendar() {
      var _this$calendarBoundar;
      if (!this.calendarBoundaries) {
        this.calendar2 = null;
        return;
      }
      const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
      const weekRange = dateRangeFromDate(lastAvailableDate, 30);
      this.calendar2 = dateRangeBoundaries([weekRange[1]], this.calendarBoundaries);
    }
  },
  created() {
    this.loadStores();
    this.loadCalendarBoundaries();
    this.loadCalendar();
  },
  computed: {
    fileName() {
      const split = this.reportSettings.date_delivery.split("-");
      const date = split[1] + "-" + split[0] + "-" + split[2];
      return `${this.marketplace.title} Расчёт поставки ${date}`;
    },
    tableKey: {
      get: function () {
        return this.refreshKey;
      },
      set: function (v) {
        this.refreshKey = v;
      }
    },
    reportSettings() {
      if (!this.calendar) {
        return null;
      }
      const dates = this.calendar.map(item => {
        const split = item.split("-");
        return split[1] + "-" + split[2] + "-" + split[0];
      });
      const split = this.calendarInternal2.split("-");
      const date = split[1] + "-" + split[2] + "-" + split[0];
      return {
        type: "DeliveryCalculation",
        hash: this.hash,
        uk_id: this.selectedTokens.map(item => item.id),
        date_start: dates[0],
        date_end: dates[1],
        clusters: this.selectedClusters.map(item => item.id),
        marketplace: this.marketplace.id,
        tokens: this.selectedTokens.map(item => item.title),
        date_delivery: date,
        count_days: this.days,
        period: dates[0] + " - " + dates[1],
        storesNames: this.selectedClusters.map(item => item.title),
        ratio: this.ratio
      };
    },
    // reportSettings2() {
    //   const dates = this.calendar.map(item => {
    //     const split = item.split("-")
    //     return split[1] + "-" + split[2] + "-" + split[0]
    //   })
    //   const split = this.calendarInternal2.split("-")
    //   const date = split[1] + "-" + split[2] + "-" + split[0]
    //   return {
    //     hash: this.hash,
    //     marketplace: this.marketplace.id,
    //     uk_id: this.selectedTokens.map(item => item.id),
    //     date_delivery: date,
    //     count_days: this.days,
    //     date_start: dates[0],
    //     date_end: dates[1],
    //     stores: this.selectedClusters.map(item => item.value),
    //     ratio: this.ratio,
    //   }
    // },
    boundariesDates() {
      return this.calendarBoundaries.map(i => i ? dayjs(i, FORMAT) : i);
    },
    calendar2: {
      get: function () {
        return this.calendarInternal2;
      },
      set: function (v) {
        this.calendarInternal2 = v[0];
      }
    }
  },
  watch: {
    marketplace() {
      this.loadStores();
    },
    calendar() {
      this.$store.dispatch("connections/updateCalendar", this.calendar);
      localStorage.setItem("calendar", JSON.stringify(this.calendar));
    },
    progress() {
      if (this.calcError != "") {
        this.loadingDeliveryCalc = false;
        this.progress = 100;
        //this.showSettings = false;
        //this.firstTime = false;
      } else if (this.progress === 100) {
        this.showSettings = false;
        this.firstTime = false;
        this.tableKey++;
      }
    }
  },
  components: {
    MainTitle,
    ReportPage,
    AppSelect,
    AppSelectMulti,
    Calendar,
    LoadingSpinner,
    HasTokens,
    DataLoading,
    DeliveryCalculationTable,
    DataTableActions,
    Tooltip
  }
};